<template>
    <div class="user">
        <div class="main" v-if="isLogin">
            <div class="side-fixed">
                <div class="container">
                    <div class="side">
                        <ul class="side_ul">
                            <li
                                @click="selectMenu(item, index)"
                                v-for="(item, index) in menuList"
                                :key="item.id"
                                :class="{side_ul_li: true, side_ul_li_active: checkIndex == index}"
                            >
                              <i class="iconfont" :class="item.icon"></i>
                                 {{item.name}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="detail">
                <div class="content">
                    <router-view />
                </div>
            </div>
        </div>
        <div v-else style="margin-top: 200px">
            <el-empty description="登录后可继续操作">
                <el-button type="danger" @click="$login.show()">去登录</el-button>
            </el-empty>
        </div>
    </div>
</template>

<script>
import {  mapGetters } from 'vuex'
    export default {
        name: 'user-index',

        data() {
            return {
                menuList: [{
                    id: 0,
                    name: '会员中心',
                    path: '/user/center',
                    icon: 'icon-tubiaolunkuo-'
                }, {
                    id: 1,
                    name: '我的订单',
                    path: '/user/order',
                    icon: 'icon-dingdan'
                }, {
                    id: 2,
                    name: '我的收藏',
                    path: '/user/collect',
                    icon: 'icon-shoucang'
                }, {
                    id: 3,
                    name: '浏览记录',
                    path: '/user/record',
                    icon: 'icon-jilu'
                }, {
                    id: 4,
                    name: '账号设置',
                    path: '/user/setting',
                    icon: 'icon-shezhi'
                }, {
                    id: 5,
                    name: '我的余额',
                    path: '/user/balance',
                    icon: 'icon-keyongyue'
                }, {
                    id: 6,
                    name: '发票管理',
                    path: '/user/invoice',
                    icon: 'icon-fapiao'
                }, {
                    id: 7,
                    name: '消息通知',
                    path: '/user/message',
                    icon: 'icon-xiaoxi1'
                },  {
                    id: 9,
                    name: '我的需求',
                    path: '/user/demand',
                    icon: 'icon-xuqiufenxi'
                },{
                    id: 8,
                    name: '我的下载',
                    path: '/user/document',
                    icon: 'icon-xiazai2'
                }],   // 菜单列表
                checkIndex: 0,   // 选中菜单
            }
        },
        mounted() {
           this.initIndex()

        },
        computed: {
          ...mapGetters(['isLogin']),
        },
        watch:{
            '$route'(val) {
                this.initIndex()
            }
        },
        methods: {
            initIndex() {
                this.checkIndex = this.menuList.findIndex(item => item.path === this.$route.path)
            },
            selectMenu(item, index) {
                if (this.checkIndex === index) return
                this.checkIndex = index
                this.$router.push({
                    path: item.path
                })
            },
        }
    }
</script>
<style lang="scss">
    .user{
        .box-card{
            height: 745px;
        }
        .list-card{
            height: 715px;
            overflow: auto;
        }
        .list-item{
            cursor: pointer;
            display: flex;
            border-radius: 2px;
            border-bottom: 1px solid $border_2;
            align-items: center;
            padding:  10px 0;
            .left{
                display: block;
                line-height: 1.5;
                flex: 0.7;
                .title{
                    font-size:16px;
                    font-family:PingFangSC-Semibold,PingFang SC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    line-height:28px;
                }
                .description{
                    font-size:14px;
                    font-weight:400;
                    line-height:20px;
                    margin-top: 6px;
                    color: $font_1;
                }
                .search_line{
                    color: $font_2;
                    font-size: 13px;
                }
                .time{
                    font-size:12px;
                    font-weight:400;
                    margin-top: 6px;
                    color: $font_2
                }

            }
            .right{
                flex: 0.3;
                line-height: 1.4;
                text-align: center;
                display: block;
                cursor: pointer;
            }
        }
    }
</style>
<style lang="scss" scoped>
    .user {
        //position: relative;
        width: 100%;
        height: 100%;
        min-height: 100%;
        //margin: -80px auto;
        .main {
            display: flex;
        }
        .side-fixed {
            //position: relative;
            //width: 14%;
            //top: 97px;
            //bottom: 0;
            //left: 10px;
            .container {
                //width: 85%;
                //height: 100%;
                //min-width: 85%;
                //max-width: 100%;
                margin: 0 auto;
            }
            .side {
                position: relative;
                width: 148px;
                height: 748px;
                border: 1px solid #eee;
                border-radius: 6px;
                background: #fff;
                overflow-y: auto;
                .side_ul {
                    list-style: none;
                    padding: inherit;
                    text-align: center;
                    font-size: 15px;
                    color: #222;
                }
                .side_ul_li {
                    height: 55px;
                    line-height: 55px;
                    cursor: pointer;
                }
                .side_ul_li:hover {
                    background: #E5EFFF;
                    color: #2878FF;
                }
                .side_ul_li_active {
                    background: #E5EFFF;
                    color: #2878FF;
                }
            }
        }
        .detail {
            //position: relative;
            z-index: 90;
            //float: right;
            width: 100%;
             margin-left: 19px;
            //width: 1088px;
            //margin: 88px 0 0;
            .content {
              height: 748px;
                //min-height: 69px;
                //margin-top: 10px;
              //margin-left: 30px;
            }
            .copyright {
                padding: 20px 0 40px;
                line-height: 20px;
                font-size: 12px;
                color: #888;
                text-align: center;
            }
        }
    }
</style>
